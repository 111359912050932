
import InProcessingClassDetailsComponent from '@/components/Course/InProcessingClassDetailsComponent.vue';
import { InProcessingClass } from '@/models/Entities/Student/InProcessingClass';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component<InProcessingClassDetailsPage>({
  components: {
    InProcessingClassDetailsComponent
  }
})
export default class InProcessingClassDetailsPage extends Vue {
  @Prop()
  inProcessingClass!: InProcessingClass;

  async saveClass(inProClass: InProcessingClass): Promise<void> {
    try {
      await this.$store.dispatch('inProcessingClassModule/update', {
        id: inProClass.id,
        payload: inProClass
      });
      this.showSuccess('Class saved');
    } catch (err: unknown) {
      this.showError('An error occurred while saving the class');
    }
  }

  showSuccess(message: string): void {
    this.$store.dispatch('snackBarModule/enqueue', {
      message,
      icon: 'checkGreen'
    });
  }
  showError(message = 'An error occurred'): void {
    this.$store.dispatch('snackBarModule/enqueue', {
      message,
      icon: 'caution'
    });
  }
}
