var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("in-processing-class-details-component", {
    on: { save: _vm.saveClass },
    model: {
      value: _vm.inProcessingClass,
      callback: function ($$v) {
        _vm.inProcessingClass = $$v
      },
      expression: "inProcessingClass",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }