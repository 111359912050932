
import { InProcessingClass } from '@/models/Entities/Student/InProcessingClass';
import { Component, VModel, Vue } from 'vue-property-decorator';
import PageLayout from '@/components/Layout/PageLayoutComponent.vue';
import { formatDate } from '@/services/formatService';
import DateSelector from '@/components/Forms/Elements/DateSelectorComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import Routes from '@/router/Routes';

@Component<InProcessingClassDetailsComponent>({
  components: {
    PageLayout,
    DateSelector,
    ButtonComponent
  }
})
export default class InProcessingClassDetailsComponent extends Vue {
  routes = Routes;
  get pathing(): Routes {
    return this.fromReports === 'true'
      ? Routes.REPORTS_DASHBOARD
      : Routes.STUDENT_DASHBOARD;
  }

  get naming(): string {
    return this.fromReports === 'true' ? 'Reports /' : '340th Dashboard /';
  }

  async mounted(): Promise<void> {
    this.setLocation();
  }

  fromReports = 'false';

  setLocation(): void {
    if (this.$route.params.fromReports)
      this.fromReports = this.$route.params.fromReports;
  }

  @VModel()
  inProcessingClass!: InProcessingClass;

  get classStartDate(): string {
    return formatDate(this.inProcessingClass.classStartDate);
  }

  get valid(): boolean {
    return (
      this.inProcessingClass.classStartDate != null &&
      this.inProcessingClass.classEndDate != null
    );
  }

  routeToPreviousPage() {
    this.$router.push({ name: Routes.INPRO_CLASSES });
  }

  saveInProClass(): void {
    this.$emit('save', this.inProcessingClass);
  }
}
